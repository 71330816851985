function toggleStakeMenu(targetStakeMenu, shouldShow) {
    targetStakeMenu.classList.toggle("translate-x-full", !shouldShow);
}

document.querySelectorAll("button[data-toggle-stake-menu]").forEach(btn => {
    btn.addEventListener("click", function (e) {
        let targetStakeMenu = e.currentTarget.closest(".dialog-step-2");
        let targetFooterDetails = e.currentTarget.closest(".dialog").querySelector(".dialog-footer-details");

        if(targetStakeMenu === null) {
            targetStakeMenu = e.currentTarget.closest(".dialog-body").parentElement.querySelector(".dialog-step-2");
        }

        targetFooterDetails.classList.toggle("max-h-0", e.currentTarget.dataset.toggleStakeMenu !== "true");
        targetFooterDetails.classList.toggle("max-h-64", e.currentTarget.dataset.toggleStakeMenu === "true");

        toggleStakeMenu(targetStakeMenu, e.currentTarget.dataset.toggleStakeMenu === "true");
    });
});

document.querySelectorAll(".toggle-details-button").forEach(btn => {
    btn.addEventListener("click", function (e) {
        e.currentTarget.dataset.toggled = !(e.currentTarget.dataset.toggled === "true");
        let targetDetails = e.currentTarget.closest(".dialog-footer").querySelector(".dialog-footer-details");
        targetDetails.classList.toggle("max-h-0", e.currentTarget.dataset.toggled !== "true");
        targetDetails.classList.toggle("max-h-64", e.currentTarget.dataset.toggled === "true");

        if(e.currentTarget.dataset.toggled === "true") {
            e.currentTarget.querySelector(".toggle-details-button-state-true").classList.remove("hidden");
            e.currentTarget.querySelector(".toggle-details-button-state-false").classList.add("hidden");
        } else {
            e.currentTarget.querySelector(".toggle-details-button-state-false").classList.remove("hidden");
            e.currentTarget.querySelector(".toggle-details-button-state-true").classList.add("hidden");
        }

    });
});